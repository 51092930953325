@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;600;800&display=swap');

/*
 * [Modified] Modern CSS Reset
 * @link https://github.com/hankchizljaw/modern-css-reset
*/

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

html,
body {
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}

html,
header {
  transition: background-color 0.3s, color 0.3s;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  font-size: 100%;
  line-height: 1.5;
  font-family: Poppins;
  text-rendering: optimizeSpeed;
}

/* Make images easier to work with */
img {
  display: block;
  max-width: 100%;
  user-select: none;
  -moz-window-dragging: false;
  -webkit-user-drag: none;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }

  html {
    scroll-behavior: initial;
  }
}

.scroller {
  max-width: max(300px, 70vw);
}

.scroller__inner {
  padding-block: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.scroller[data-animated='true'] {
  overflow: hidden;
  -webkit-mask: linear-gradient(
    90deg,
    transparent,
    white 20%,
    white 80%,
    transparent
  );
  mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
}

.scroller[data-animated='true'] .scroller__inner {
  width: max-content;
  flex-wrap: nowrap;
  animation: scroll var(--_animation-duration, 24s)
    var(--_animation-direction, forwards) linear infinite;
}

@keyframes scroll {
  to {
    transform: translate(calc(-50% - 0.5rem));
  }
}

/* general styles */

.tag-list {
  margin: 0;
  padding-inline: 0;
  list-style: none;
}

.tag-list li {
  font-size: min(14px, 3vw);
  font-weight: bold;
  padding: 1rem 1.5rem;
  outline: 3px solid #875ffa;
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem -0.25rem var(--clr-primary-900);
}

/* for testing purposed to ensure the animation lined up correctly */
.test {
  background: red !important;
}

.heading {
  font-size: clamp(2rem, 5vw, 5rem);
}

.heading-2 {
  font-size: clamp(1.5rem, 3vw, 5rem);
}

.sub-heading {
  font-size: clamp(1rem, 1.5vw, 2rem);
}

.fa {
  font-size: 25px;
}
